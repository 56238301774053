import { React, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import "./FAQ.css"

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));



function FAQ({ text }) {
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    return (
        <div className="faq__body">
            <div className="faq__container">
                <h2 style={{marginBottom:'20px'}}>Frequently Asked Questions</h2>
                {text.map( (textDisplayed, i) => 
                    <div key={i} >
                        <Accordion expanded={expanded === 'panel'+i } onChange={handleChange('panel'+i)} >
                            <AccordionSummary aria-controls={"panel"+i+"d-content"} id={"panel"+i+"d-header"}>
                                <Typography>{textDisplayed['Question']}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>{textDisplayed['Answer']}</Typography>
                                </AccordionDetails>
                        </Accordion>
                    </div>
                    )
                }
            </div>
        </div>
    )
}

export default FAQ
