import React from 'react';
import { useState } from 'react';

import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import RemoveCircleIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { ThemeProvider } from '@mui/material/styles';

import moment from 'moment';

import {themeGAD, colorTheme, StyledTextField} from "./AboutYou.js"


function PartTimePeriod( {index, partTime, setPartTime, periodFrom, periodTo, periodProportion, dateJoinedScheme, dateLeftScheme, 
                            validPartTimeFlag, setValidPartTimeFlag, fillPartTimeFlag, setFillPartTimeFlag  } ) 
{
    const [errorFrom, setErrorFrom] = useState(true);
    const [errorTo, setErrorTo] = useState(true);

    const [errorFromText, setErrorFromText] = useState("");
    const [errorToText, setErrorToText] = useState("");


    const isValidPartTime = () => {
        return (!errorFrom && !errorTo && periodProportion !== null && periodProportion !== '')
    }

    const handlePartTimeInput = (index, event, target) => {
        const values = [...partTime];
        if (target !== "proportion") {
            if (event !== null && event['_isValid']) {

                if (target === 'from'){
                    if (index === 0 && event < dateJoinedScheme) {
                        setErrorFrom(true)
                        setErrorFromText("Date is before you joined")
                    } else if (index > 0 && event <= values[index-1]['to']) {
                        setErrorFrom(true)
                        setErrorFromText("Date is before last period of part time service ended")
                    } else {
                        setErrorFrom(false)
                        setErrorFromText(" from ")
                        values[index][target] = event
                    }
                    if (values[index]['to'] !== null && (event > values[index]['to'])) {
                        setErrorTo(true)
                        setErrorToText("Date is before 'From' date")
                    }
                } else if (target === 'to') {
                    if (values[index]['to'] !== null && event < values[index]['from']) {
                        setErrorTo(true)
                        setErrorToText("Date is before 'From' date")
                    } else if (dateLeftScheme !== null && event > dateLeftScheme) {
                        setErrorTo(true)
                        setErrorToText("Date is after you left scheme")
                    } else {
                        setErrorTo(false)
                        setErrorToText(" to ")
                        values[index][target] = event
                    } 
                    if (values[index]['from'] !== null && (event < values[index]['from'])) {
                        setErrorTo(true)
                        setErrorToText("Date is before 'From' date")
                    } 
                    
                }

            } else {
                
            }
        } else {
            if (event.target.value > 100) {
                values[index][target] = 100
            } else if (event.target.value < 0) {
                values[index][target] = 0
            } else {
                values[index][target] = event.target.value
            }
            
        }
        
        
        setPartTime(values)
        setValidPartTimeFlag(isValidPartTime())
        
        // console.log({
        //     'doj':dateJoinedScheme,
        //     'dol':dateLeftScheme,
        //     'index':index,
        //     'event':event,
        //     'target':target,
        //     'values': values

        // })

    };



    const handleRemovePartTimeField = (index) => {
        const values = [...partTime];
        const newValues = []
        values.forEach(function(item, valuesIndex) {
            if (index !== valuesIndex) {
                newValues.push(item)
            } 
        })
        setPartTime(newValues);
        setFillPartTimeFlag(false)
        setValidPartTimeFlag(true)
    };

    setValidPartTimeFlag(isValidPartTime())

    // console.log({
    //     'errorFrom':errorFrom,
    //     'errorTo':errorTo,
    //     'parttime':partTime,
    //     'valid':validPartTimeFlag,
    //     'fill':fillPartTimeFlag
    // })



    return (
        <LocalizationProvider dateAdapter={DateAdapter} key={index}>
            <div className="parttime__container">
                <ThemeProvider theme={themeGAD}>
                    <div className="parttime__period">
                    <Typography sx={{ mt: 2, mb: 1, fontSize:"12px", color:themeGAD}}>{index+1}</Typography>  
                    </div>
                    <div className="parttime__datepicker">
                        <DesktopDatePicker 
                        disabled = {index < partTime.length-1}
                        label="From"
                        inputFormat="DD/MM/yyyy"
                        value={moment(periodFrom)}
                        minDate={index === 0 ? moment(dateJoinedScheme) : partTime[index-1]['to'].clone().add(1,'days')}
                        maxDate={dateLeftScheme !== null ? moment(dateLeftScheme) : undefined}
                        onChange={event => handlePartTimeInput(index, event, "from")}
                        renderInput={(params) => <TextField {...params}
                        helperText={errorFrom ? errorFromText : " "} />}
                        />   
                    </div>
                    <div className="parttime__datepicker">
                        <DesktopDatePicker 
                        disabled = {index < partTime.length-1}
                        label="To"
                        inputFormat="DD/MM/yyyy"
                        value={moment(periodTo)}
                        minDate={moment(periodFrom)}
                        maxDate={dateLeftScheme !== null ? moment(dateLeftScheme) : undefined}
                        onChange={event => handlePartTimeInput(index, event, "to")}
                        renderInput={(params) => <TextField {...params}
                        helperText={errorTo ? errorToText : " "} />}
                        />   
                    </div>
                    <StyledTextField
                        className="parttime__proportion"
                        disabled = {index < partTime.length-1}
                        label="Percentage (%)" 
                        variant="standard"
                        value={periodProportion} 
                        style={{marginLeft:"10px", marginTop:"27px", color:colorTheme}}
                        type="number"
                        onChange={event => handlePartTimeInput(index, event, "proportion")} 
                    />
                    <Tooltip title={<Typography fontSize={'16px'}>
                        Please enter the start and end dates for any periods of part-time working. 
                        The “percentage” should be the proportion of whole-time equivalent hours worked over 
                        the period in question (so if whole-time hours per week for the role is 40, and you 
                        worked 20 hours per week, the percentage is 50%).</Typography>}>
                        <IconButton id="pt__infobutton" >
                            <InfoIcon id="pt__infobutton" />
                        </IconButton>
                    </Tooltip>
                    
                    {index === 0 ? (
                        null
                    ) : (
                        <RemoveCircleIcon className="parttime__addremove" 
                        style={{fontSize:"30px", color:colorTheme}}
                        onClick={() => handleRemovePartTimeField(index)}/>
                    )}
                    <div className="parttime__validated">
                         {isValidPartTime() && <CheckCircleOutlineIcon style={{fontSize:"30px", color:'#4b9560'}}/> }
                    </div> 
                </ThemeProvider>
            </div>
        </LocalizationProvider>
    )
}

export default PartTimePeriod