import React from 'react';
import "./CarouselPanel.css";

import { useState } from 'react';
import {Line, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, BarChart, LineChart, ResponsiveContainer, ReferenceLine } from 'recharts';

import TooltipMui from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';

function CarouselPanel( { panelTitle, annualPension, lumpSum, viewOption, legendLabels, loading, 
                        titleInfoText, deferredAge, currentPanel, scheme } ) {

 
    const CustomTooltipValues = ({ active, payload, label }) => {
        if (payload !== null && active && !loading && payload.length === 2) {
            return (
                <div className="customTooltip">
                    <p>Age : {`${label}`}</p>
                    <p style={{color:legendLabels[0]['color']}}>Switch in 2015: {'£' + (Math.round(`${payload[0].value}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    <p style={{color:legendLabels[1]['color']}}>Switch in 2022: {'£' + (Math.round(`${payload[1].value}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                </div>
            );
        } else if (payload !== null && active && !loading && payload.length === 1){
            return (
                <div className="customTooltip">
                    <p>Age : {`${label}`}</p>
                    <p>Pension: {'£' + (Math.round(`${payload[0].value}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                </div>
            );            
        }
        return null;
    }

    const CustomTooltipProportion = ({ active, payload, label }) => {
        if (payload !== null && active && !loading && payload.length === 2) {
            return (
                <div className="customTooltip">
                    <p>Age : {`${label}`}</p>
                    <p style={{color:legendLabels[0]['color']}}>Switch in 2015: {`${(payload[0].value*100).toFixed()}%`}</p>
                    <p style={{color:legendLabels[1]['color']}}>Switch in 2022: {`${(payload[1].value*100).toFixed()}%`}</p>
                </div>
            );
        } else if (payload !== null && active && !loading && payload.length === 1) {
            return (
                <div className="customTooltip">
                    <p>Age : {`${label}`}</p>
                    <p>Pension: {`${(payload[0].value*100).toFixed()}%`}</p>
                </div>
            );            
        }
        return null;
    }
    const CustomTooltipValuesLumpSum = ({ active, payload, label }) => {
        if (payload !== null && active && !loading && payload.length === 2) {
            return (
                <div className="customTooltip">
                    <p>Age : {`${label}`}</p>
                    <p style={{color:legendLabels[0]['color']}}>Switch in 2015: {'£' + (Math.round(`${payload[0].value}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    <p style={{color:legendLabels[1]['color']}}>Switch in 2022: {'£' + (Math.round(`${payload[1].value}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                </div>
            );
        } else if (payload !== null && active && !loading && payload.length === 1){
            return (
                <div className="customTooltip">
                    <p>Age : {`${label}`}</p>
                    <p>Lump Sum: {'£' + (Math.round(`${payload[0].value}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                </div>
            );            
        }
        return null;
    }

    const CustomTooltipProportionLumpSum = ({ active, payload, label }) => {
        if (payload !== null && active && !loading && payload.length === 2) {
            return (
                <div className="customTooltip">
                    <p>Age : {`${label}`}</p>
                    <p style={{color:legendLabels[0]['color']}}>Switch in 2015: {`${(payload[0].value*100).toFixed()}%`}</p>
                    <p style={{color:legendLabels[1]['color']}}>Switch in 2022: {`${(payload[1].value*100).toFixed()}%`}</p>
                </div>
            );
        } else if (payload !== null && active && !loading && payload.length === 1) {
            return (
                <div className="customTooltip">
                    <p>Age : {`${label}`}</p>
                    <p>Lump Sum: {`${(payload[0].value*100).toFixed()}%`}</p>
                </div>
            );            
        }
        return null;
    }
    

    const [lineProps, setLineProps] = useState(
        legendLabels.reduce(
          (a, { key }) => {
            a[key] = false;
            return a;
          },
          { hover: null }
        )
      );

    const handleLegendMouseEnter = (e) => {
        if (!lineProps[e.dataKey]) {
          setLineProps({ ...lineProps, hover: e.dataKey });
        }
      };
    
    const handleLegendMouseLeave = (e) => {
        setLineProps({ ...lineProps, hover: null });
      };
    
    const selectLine = (e) => {
        setLineProps({
          ...lineProps,
          [e.dataKey]: !lineProps[e.dataKey],
          hover: null
        });
      };

    // console.log(annualPension[viewOption][annualPension[viewOption].length -1][legendLabels[1]['key']]*6)
    // console.log(Math.ceil(annualPension[viewOption][annualPension[viewOption].length -1][legendLabels[1]['key']]/10000)*10000*6)
    // console.log(Math.max(lumpSum[viewOption][0]['Move to CARE in 2015'], lumpSum[viewOption][0]['Move to CARE in 2022']))
    // console.log(Math.ceil(Math.max(lumpSum[viewOption][0]['Move to CARE in 2015'], lumpSum[viewOption][0]['Move to CARE in 2022'])/10000)*10000)
 
    const maxPension = annualPension[viewOption][annualPension[viewOption].length -1][legendLabels[1]['key']]
    
    const pensionTickArray = [0, 
        Math.ceil(maxPension/10000)*10000/4, 
        Math.ceil(maxPension/10000)*10000/2, 
        Math.ceil(maxPension/10000)*10000*3/4, 
        Math.ceil(maxPension/10000)*10000]

    // console.log(lumpSum)
    // console.log(0 in lumpSum[viewOption])
    var maxLumpSum = maxPension
    if (0 in lumpSum[viewOption]) {
        maxLumpSum = Math.max(lumpSum[viewOption][0]['Move to CARE in 2015'], lumpSum[viewOption][0]['Move to CARE in 2022'])
    } 
    
    
    const lumpSumTickArray = [0, 
        Math.ceil(maxLumpSum/10000)*10000/4, 
        Math.ceil(maxLumpSum/10000)*10000/2, 
        Math.ceil(maxLumpSum/10000)*10000*3/4, 
        Math.ceil(maxLumpSum/10000)*10000]

    return (
        <div className="carouselpanel__container">

            <div className="carouselpanel__title">
                {currentPanel !== 4 && currentPanel !==5 ? (
                    <h3>{panelTitle}</h3>
                ) : (
                    <h3>{panelTitle} {deferredAge}</h3>
                )}
                <TooltipMui title={<Typography fontSize={'16px'}>{titleInfoText}</Typography>}>
                    <IconButton id="carouselpanel__title__infobutton" >
                        <InfoIcon id="carouselpanel__title__infobutton" />
                    </IconButton>
                </TooltipMui>
            </div>
            <div className="carouselpanel__title">
                <Typography sx={{ mt: 2, mb: 1 }}>Hovering over results in the chart will display details of the figures being illustrated</Typography>
            </div>
            
            <div className="carouselpanel__graphs__container">
                <ResponsiveContainer width="48%" height={400}>
                    <LineChart data={annualPension[viewOption]}>
                        <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />                               
                        <XAxis 
                            dataKey="Age" 
                            label= {{ value: "Age", dy:10}} 
                            type="number"
                            height={60}
                            domain={scheme === '1987' ? [45, 70] : [50,70] }
                            ticks={scheme === '1987' ? [ 45, 50, 55, 60, 65, 70] : [ 50, 55, 60, 65, 70] }
                        />
                        {currentPanel === 2 && (
                            <ReferenceLine x={50} stroke="black" label={{value:"Minimum retirement age", angle: -90, dx:-10 }}strokeDasharray="3 3" />
                        )}

                        {viewOption === 2 ? (
                        <YAxis 
                            label= {{ value: "Annual Pension (%)", angle: -90, position: 'insideLeft', dy: 50}} 
                            tickFormatter={(tick) => { return (`${tick*100}`)}} 
                            width={80}
                            tickCount={5}
                        />
                        ) : (
                            annualPension[0] == null  ? (
                            <YAxis 
                                label={{ value: "Annual Pension (£)", angle: -90, position: 'insideLeft', dy: 50}} 
                                tickFormatter={(tick) => { return (Math.round(`${tick}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                width={80}
                                tickCount={5}
                            />
                            ) : (
                            <YAxis 
                                label={{ value: "Annual Pension (£)", angle: -90, position: 'insideLeft', dy: 50}} 
                                tickFormatter={(tick) => { return (Math.round(`${tick}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                width={80}
                                interval='preserveEnd'
                                ticks={pensionTickArray}
                                domain={[0, Math.ceil(maxPension/10000)*10000 ]}
                            />
                            )

                        )}
                        

                        {viewOption === 2 ? (
                            <Tooltip content = {<CustomTooltipProportion/>}/>
                            ) : (
                            <Tooltip content = {<CustomTooltipValues/>}/>
                            )
                        }

                        <Legend 
                            wrapperStyle={{cursor:"pointer", left:"10%"}} 
                            onClick={selectLine} 
                            onMouseOver={handleLegendMouseEnter} 
                            onMouseOut={handleLegendMouseLeave}
                            iconType={'plainline'} 
                        />
                        {legendLabels.map((label, index) => (
                        <Line
                            key={index}
                            dataKey={label.key}
                            stroke={label.color}
                            strokeWidth={2}
                            hide={lineProps[label.key] === true}
                            animationDuration={0}
                            dot={false}
                            name={label.name}
                            type="stepAfter"
                        />
                        ))}
                    </LineChart>
                </ResponsiveContainer>

                <TooltipMui title={<Typography fontSize={'16px'}>Click on the names in the legend to hide or show each option.</Typography>}>
                    <IconButton id="carouselpanel__legend__infobutton" >
                        <InfoIcon id="carouselpanel__legend__infobutton" />
                    </IconButton>
                </TooltipMui>

                <ResponsiveContainer width="48%" height={400}>
                    <BarChart data={lumpSum[viewOption]} classname="bar__graph">
                        <CartesianGrid stroke="#ccc" strokeDasharray="1 1" />
                        <XAxis 
                            dataKey="Age" 
                            label= {{ value: "Age", dy: 10}} 
                            type="number" 
                            height={60}
                            xAxisId={0}
                            domain={scheme === '1987' ? [45, 70] : [50,70] }
                            ticks={scheme === '1987' ? [ 45, 50, 55, 60, 65, 70] : [ 50, 55, 60, 65, 70] }
                        />
                        <XAxis 
                            dataKey="Age" 
                            label= {{ value: "Age", dy: 10}} 
                            type="number" 
                            height={60}
                            domain={scheme === '1987' ? [45 - 0.125, 70 - 0.125] : [50 - 0.125, 70 - 0.125] }
                            xAxisId={1}
                            hide
                        />
                        <XAxis 
                            dataKey="Age" 
                            label= {{ value: "Age", dy: 10}} 
                            type="number" 
                            height={60}
                            domain={scheme === '1987' ? [45 + 0.125, 70 + 0.125] : [50 + 0.125, 70 + 0.125] }
                            xAxisId={2}
                            hide
                        />
                        
                        {viewOption === 2 ? (
                        <YAxis 
                            label= {{ value: "Lump Sum (%)", angle: -90, position: 'insideLeft', dy: 50}} 
                            tickFormatter={(tick) => { return (`${tick*100}`)}} 
                            width={80}
                            tickCount={5}
                        />
                        ) : (
                            annualPension[0] == null ? (
                            <YAxis 
                                label={{ value: "Lump Sum (£)", angle: -90, position: 'insideLeft', dy: 50}} 
                                tickFormatter={(tick) => { return (Math.round(`${tick}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                width={80}
                                tickCount={5}
                            />

                            ) : (
                            <YAxis 
                                label={{ value: "Lump Sum (£)", angle: -90, position: 'insideLeft', dy: 50}} 
                                tickFormatter={(tick) => { return (Math.round(`${tick}`)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                width={80}
                                interval='preserveEnd'
                                ticks={lumpSumTickArray}
                                domain={[0, Math.ceil(maxLumpSum/10000)*10000 ]}
                            />
                            )
                        )}
                        {viewOption === 2 ? (
                        <Tooltip content = {<CustomTooltipProportionLumpSum/>}/>
                        ) : (
                        <Tooltip content = {<CustomTooltipValuesLumpSum/>}/>
                        )}
                        <Legend 
                            wrapperStyle={{cursor:"pointer", left:"10%"}} 
                            onClick={selectLine} 
                            onMouseOver={handleLegendMouseEnter} 
                            onMouseOut={handleLegendMouseLeave} 
                        />
                        {legendLabels.map((label, index) => (
                        <Bar
                            key={index}
                            xAxisId={index+1}
                            dataKey={label.key}
                            fill={label.color}
                            barSize={20}
                            fillOpacity={1}
                            hide={lineProps[label.key] === true}
                            animationDuration={0}
                            name={label.name}
                        />
                        ))}
                    </BarChart>
                </ResponsiveContainer>

            </div>

        </div>
    )
}

export default CarouselPanel
